<template>
  <div>
    <v-divider class="my-5" />
    <label-icon label="Espaços Potenciais" icon="bubble_chart" bold />
    <div v-if="hasPotentialSpaces">
      <v-list two-line>
        <v-list-group
          v-for="(ps, indexPs) in diagnose.potentialSpaces"
          :key="indexPs"
          class="elevation-1"
          @click="selectIndex(indexPs)"
        >
          <template v-slot:activator>
            <v-list-item class="pa-7">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ ps.potentialSpace.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Zona de Resolução:
                  {{ ps.diagnoseResolutionZone.resolutionZone.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <div>
            <view-tracking-impaction-point
              class="grey"
              :tracking-point="
                ps.diagnoseResolutionZone.resolutionZone.trackingPoint
              "
              :impaction-point="
                ps.diagnoseResolutionZone.resolutionZone.impactionPoint
              "
            />

            <div
              class="px-10 py-3 grey--text text--darken-3 body-2 text-center"
            >
              <text-with-editor
                :value="ps.diagnoseResolutionZone.affectedSpotParTrauma"
                label="Ponto afetado - Par Trauma"
                return-value="newValue"
                @save="changeAffectedSpotParTrauma"
              />
            </div>
            <v-row class="px-10 grey--text text--darken-3 body-2">
              <property-value
                property="Instruções"
                :value="ps.potentialSpace.instructions"
                class="pre-line"
              />
              <property-value
                property="Checou Associações Patogêncicas"
                :value="ps.checkedCategories | booleanToString"
              />
            </v-row>

            <v-row class="px-10 grey--text text--darken-3 body-2">
              <text-with-editor
                :value="ps.therapistComments"
                label="Comentários do terapeuta (não será exibido para o paciente)"
                return-value="newValue"
                @save="changeAffectedSpotParTrauma"
              />
            </v-row>
            <v-row class="px-10 py-3 grey--text text--darken-3 body-2">
              <text-with-editor
                :value="ps.patientComments"
                label="Comentários ao paciente"
                return-value="newValue"
                @save="changeAffectedSpotParTrauma"
              />
            </v-row>
            <v-row justify="center" class="py-5">
              <v-btn dark color="red" @click="removePotentialSpace(indexPs)">
                <v-icon class="mr-2">delete</v-icon>
                Remover
              </v-btn>
            </v-row>
          </div>
        </v-list-group>
      </v-list>
    </div>
    <div v-else class="mt-5">Nenhum espaço potencial adicionado.</div>
  </div>
</template>

<script>
export default {
  components: {
    TextWithEditor: () => import("@/components/shared/TextWithEditor"),
  },
  data: () => ({
    selectedIndex: -1,
  }),
  computed: {
    hasPotentialSpaces() {
      return this.$store.getters["diagnosis/hasPotentialSpaces"];
    },
    diagnose() {
      return this.$store.getters["diagnosis/diagnose"];
    },
  },
  methods: {
    removePotentialSpace(index) {
      const ok = confirm(
        "Tem certeza que deseja remover este ESPAÇO POTENCIAL do diagnóstico?"
      );
      if (ok) this.$store.commit("diagnosis/removePotentialSpace", index);
    },
    changeAffectedSpotParTrauma({ newValue }) {
      this.$store.commit(
        "diagnosis/changeAffectedSpotParTraumaPotentialSpace",
        {
          index: this.selectedIndex,
          newValue,
        }
      );
    },
    selectIndex(index) {
      this.selectedIndex = index;
    },
  },
};
</script>

<style></style>
